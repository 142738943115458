
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    import Meta from '@/components/common/Meta';
import ReportBug from '@/components/common/ReportBug';
import WrongPage from '@/components/common/wrong';
import { NetworkErrorPopupLazy } from '@/components/popups';
import DomainNotFoundRedirection from '@/guards/DomainNotFoundRedirection';
import SubDomainAuthRedirection from '@/guards/SubDomainAuthRedirection';
import { ApolloProviderWrapper, CreateClientOptions } from '@/lib/apollo';
import { app } from '@/lib/firebase';
import '@/lib/polyfill';
import { AuthProvider } from '@/providers/auth';
import MessageProvider from '@/providers/message';
import MobileProvider from '@/providers/mobile';
import { PreRouteProvider } from '@/providers/pre-route';
import { RemoteConfigProvider } from '@/providers/remote-config';
import '@/styles/globals.css';
import '@/styles/lexical-editor.css';
import type { GetLayout } from '@/types';
import { relayStylePagination } from '@apollo/client/utilities';
import { ModalContainer, Toaster } from '@flyer/components';
import { isClient } from '@flyer/utils';
import { getAnalytics } from 'firebase/analytics';
import { LazyMotion } from 'framer-motion';
import { NextAdapter } from 'next-query-params';
import type { AppProps } from 'next/app';
import React, { useEffect } from 'react';
import { QueryParamAdapter, QueryParamProvider } from 'use-query-params';
import TiktokPixel from 'tiktok-pixel';
import * as Sentry from '@sentry/nextjs';
import mixpanel from 'mixpanel-browser';
import { StatsigProvider } from '@statsig/react-bindings';
import { StatsigClient } from '@statsig/js-client';
type AppPropsWithComponentLayout<P = Record<string, string>> = AppProps<P> & {
    Component: AppProps['Component'] & {
        getLayout?: GetLayout;
    };
};
function Adapter(props: {
    children: (adapter: QueryParamAdapter) => React.ReactElement | null;
}) {
    return <NextAdapter {...props} shallow/>;
}
const defaultGetLayout: GetLayout = (page) => page;
const loadFeatures = () => import('@/lib/feature-motion').then((res) => res.domAnimation);
const createClientOptions: CreateClientOptions = {
    typePolicies: {
        Query: {
            fields: {
                checkPoints: relayStylePagination(['filterBy']),
                viewerInProgressAssignedTests: relayStylePagination(['filterBy']),
                viewerAssignedTestUserAttempts: relayStylePagination(['filterBy']),
                viewerMistakeWords: relayStylePagination(),
                viewerExamNotifications: relayStylePagination(),
                viewerUserAttempts: relayStylePagination(['filterBy'])
            }
        }
    }
};
const myStatsigClient = new StatsigClient('client-68pNq8PHlCP4TQzxpiFPhOGwkwqPhmpVJmPcuH6ORDR', {
    userID: 'anonymous'
});
myStatsigClient.initializeSync();
function App({ Component, pageProps }: AppPropsWithComponentLayout): JSX.Element {
    const getLayout = Component.getLayout || defaultGetLayout;
    // const handleRotateScreen = useCallback(() => {
    //   if (window.innerWidth < 640) {
    //     toast.warning(t('toast_error.desc_rotate'), {
    //       title: t('toast_error.title_rotate'),
    //       important: true,
    //       id: 'alert_rotate',
    //     });
    //   }
    // }, []);
    // useEffect(() => {
    //   void handleRotateScreen();
    // }, [handleRotateScreen]);
    useEffect(() => {
        if (isClient()) {
            getAnalytics(app);
            void TiktokPixel.init('CQJNA2JC77UE89C5KM9G');
            mixpanel.init('48f911e37c403b345fab5a3fcb8251b2', {
                debug: false,
                track_pageview: true,
                persistence: 'localStorage'
            });
        }
        fetch('/exam/api/cookies', {
            method: 'get'
        })
            .then((res) => res.json())
            .then((jsonData: {
            cookie: {
                [key: string]: string;
            };
        }) => {
            const scope = Sentry.getCurrentScope();
            scope.setExtra('cookie', jsonData.cookie);
        })
            .catch(console.error);
    }, []);
    return (<>
      <StatsigProvider client={myStatsigClient}>
        <QueryParamProvider adapter={Adapter}>
          <ApolloProviderWrapper pageProps={pageProps} options={createClientOptions}>
            <MobileProvider>
              <DomainNotFoundRedirection>
                <SubDomainAuthRedirection>
                  <AuthProvider>
                    <RemoteConfigProvider>
                      <PreRouteProvider>
                        <MessageProvider>
                          <LazyMotion strict features={loadFeatures}>
                            <ModalContainer />
                            <Toaster />
                            {getLayout(<Component {...pageProps}/>)}
                            <ReportBug />
                            <NetworkErrorPopupLazy />
                            <WrongPage />
                            <Meta />
                          </LazyMotion>
                        </MessageProvider>
                      </PreRouteProvider>
                    </RemoteConfigProvider>
                  </AuthProvider>
                </SubDomainAuthRedirection>
              </DomainNotFoundRedirection>
            </MobileProvider>
          </ApolloProviderWrapper>
        </QueryParamProvider>
      </StatsigProvider>
      {/* {!ENVIRONMENT.IS_DEV && isFlyerUs() && <GoogleTagManager gtmId="G-7XSG8Y4ZRL" />} */}
    </>);
}
const __Next_Translate__Page__1925583f20f__ = App;

    export default __appWithI18n(__Next_Translate__Page__1925583f20f__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: true,
      loadLocaleFrom: __i18nConfig.loadLocaleFrom || (() => Promise.resolve({})),
    });
  